import dynamic from 'next/dynamic';
import { getDocumentFields } from 'utils/helper';
import { HOMEPAGE_SECTION_TYPES } from 'utils/constants';

const TextSection = dynamic(() => import('UI/sections/TextSection'));
const CardsSection = dynamic(() => import('UI/sections/CardsSection'));
const FeedbackSection = dynamic(() => import('UI/sections/FeedbackSection'));
const SvgListSection = dynamic(() => import('UI/sections/SvgListSection'));
const ReviewsSection = dynamic(() => import('UI/sections/ReviewsSection'));
const Blog = dynamic(() => import('UI/sections/Blog'));
const PhotoGallery = dynamic(() => import('components/Common/PhotoGallery'));
const TeamSection = dynamic(() => import('UI/sections/Team'));
const InfinityScrollingCarousel = dynamic(() => import('UI/sections/InfinityScrollingCarousel'));
const CardsSlider = dynamic(() => import('UI/sections/CardsSlider'));
const SvgList = dynamic(() => import('UI/sections/SvgList'));
const Card = dynamic(() => import('UI/sections/Card'));
const ScrollingTabs = dynamic(() => import('UI/sections/ScrollingTabs'));
const Works = dynamic(() => import('components/HomeCommon/Works'));
const Cta = dynamic(() => import('UI/sections/Cta'), { ssr: false });

const SectionSelector = ({
  section,
  type,
  ...rest
}) => {
  const { type: sectionType } = getDocumentFields(section, ['type']);

  switch (sectionType) {
  case HOMEPAGE_SECTION_TYPES.text:
    return (
      <TextSection
        sectionData={section}
        type={type}
      />
    );

  case HOMEPAGE_SECTION_TYPES.cards:
    return (
      <CardsSection
        section={section}
        type={type}
        sectionType="cards"
      />
    );

  case HOMEPAGE_SECTION_TYPES.cardsWithOverlay:
    return (
      <CardsSection
        section={section}
        type={type}
        withOverlay
      />
    );

  case HOMEPAGE_SECTION_TYPES.svgDisplay:
    return (
      <SvgListSection
        section={section}
        type={type}
      />
    );

  case HOMEPAGE_SECTION_TYPES.svgDisplayWithSelector:
    return (
      <SvgListSection
        section={section}
        type={type}
        withSelector
      />
    );

  case HOMEPAGE_SECTION_TYPES.reviews:
    return (
      <ReviewsSection
        section={section}
        type={type}
      />
    );

  case HOMEPAGE_SECTION_TYPES.blog:
    return (
      <Blog
        sectionData={section}
        {...rest}
      />
    );

  case HOMEPAGE_SECTION_TYPES.photos:
    return <PhotoGallery sectionData={section} />;

  case HOMEPAGE_SECTION_TYPES.feedback:
    return (
      <FeedbackSection
        section={section}
        type={type}
      />
    );

  case HOMEPAGE_SECTION_TYPES.team:
    return (
      <TeamSection
        data={section}
        type={type}
      />
    );

  case HOMEPAGE_SECTION_TYPES.infinityScrollingCarousel:
    return (
      <InfinityScrollingCarousel
        section={section}
        type={type}
      />
    );

  case HOMEPAGE_SECTION_TYPES.cardsSlider:
    return (
      <CardsSlider
        section={section}
        type={type}
      />
    );

  case HOMEPAGE_SECTION_TYPES.svgList:
    return (
      <SvgList
        section={section}
        type={type}
      />
    );

  case HOMEPAGE_SECTION_TYPES.card:
    return (
      <Card
        data={section}
        type={type}
      />
    );

  case HOMEPAGE_SECTION_TYPES.scrollingTabs:
    return (
      <ScrollingTabs
        section={section}
        type={type}
      />
    );

  case HOMEPAGE_SECTION_TYPES.portfolio:
    return (
      <Works section={section} />
    );

  case HOMEPAGE_SECTION_TYPES.cta:
    return (
      <Cta
        section={section}
        type={type}
      />
    );

  default:
    return null;
  }
};

export default SectionSelector;
